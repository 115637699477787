import React, { useState, useRef } from 'react';
import { Modal } from '../common/Modal';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { ComboBox } from '../common/Combobox';
import { DatePicker } from '../common/DatePicker';
import { SapNumberInput } from '../SapNumberInput';
import {
  Body,
  Header,
  Title,
  HighlightedText,
  StatusBox,
  DeliveryAndClassificationBox,
  DeliveryBox,
  ClassificationBox,
  SubTitle,
  SapDeliveryWindowAndTriageBox,
  DeliveryWindowRadioButtonBox,
  ResponsibleOperatorText,
} from './styles';
import { Checkbox } from '../common/Checkbox';
import { MultipleSelect } from '../common/MultipleSelect';
import { useOrders } from '../../features/useOrders';
import { TriageField } from '../triage';
import { Tooltip } from '../common/Tooltip';
import { UserHelper } from '../../helpers/UserHelper';
import { CurrentUser } from '../../types/User';
import { useOrderStore } from '../../stores/ordersStore';
import { ErrorModal } from '../../components/error';
import { InfoModal } from '../../components/common/Modal/InfoModal';
import { BsFillFileEarmarkPlusFill } from 'react-icons/bs';

type EditOrderModalProps = {
  open: boolean;
  handleClose: () => void;
  order_id?: number;
  refreshOrdersTable: () => void;
};

export const EditOrderModal: React.FC<EditOrderModalProps> = ({
  open,
  handleClose,
  order_id,
  refreshOrdersTable,
}) => {
  const {
    order,
    setOrder,
    errorValidationMessage,
    visibleSelectItems,
    handleChangeStatus,
    handleChangeStatusComplement,
    handleChangeDeliveryOperation,
    handleChangeServiceEvent,
    handleChangeRetreatReason,
    handleCheckDeliveryWindow,
    handleChangeDistributionCenter,
    
    fetchCurrentOrder,
    getMultiSelectData,
    handleSave,
    handleClickTakeResponsibility,
  } = useOrders({
    refreshOrdersTable,
    handleClose,
    order_id,
  });

  const [currentUser, setCurrentUser] = React.useState<
    CurrentUser | undefined
  >();

  React.useEffect(() => {
    setCurrentUser(UserHelper.getCurrentUser());
    getMultiSelectData();
    fetchCurrentOrder(order_id);
  }, [open]);
    
  const [showCECErrorModal, setShowCECErrorModal] = useState(false);
  const [showCECSuccessModal, setShowCECSuccessModal] = useState(false);
  
  const uploadCEC = useOrderStore((state) => state.uploadCEC);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [CECsent, setCECsent] = useState(false);
  const fetchOrders = useOrderStore((state) => state.fetchOrders);

  const uploadCECFile = async () => {
    hiddenFileInput.current?.click();
  };
  const handleChangeCECFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = e.target.files;

    if (files) {
      const allFiles: { name: string; content: string | ArrayBuffer | null }[] = [];

      const filePromises = Array.from(files).map((file) => {
        const fileName = file.name.split('.')[0];
        const isNumberRegex = /^[0-9]*$/g;

        if (fileName.length !== 8 || !isNumberRegex.test(fileName)) {
          setShowCECErrorModal(true);
          return;
        }

        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = async function (e) {
            const fileContent = e.target?.result;
            if (fileContent !== undefined) {
              allFiles.push({
                name: fileName,
                content: fileContent,
              });
              resolve(fileContent);
            } else {
              reject(new Error('File content is undefined'));
            }
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      });

      await Promise.all(filePromises);

      if (allFiles.length !== 0) {
        const currentUser = UserHelper.getCurrentUser();
        if (currentUser) {
          allFiles.forEach(async (file) => {
            await uploadCEC({
              request_number: file.name,
              user: currentUser.username,
              files: [file],
            });
          });

          allFiles.forEach((file) => {
            if (!order.request_number.includes(file.name)) {
              const new_request_number = [...order.request_number];
              if (new_request_number[new_request_number.length - 1] === '') {
                new_request_number[new_request_number.length - 1] = file.name;
              } else {
                new_request_number.push(file.name);
              }
              setOrder({
                ...order,
                request_number: new_request_number,
              });
            }
          });
          setShowCECSuccessModal(true);
          setCECsent(true);
          fetchOrders(currentUser.cnpj);
        } else {
          console.error('Usuário não encontrado.');
        }
      }
    }
  };

  return (
    <>
      <Modal open={open} handleClose={handleClose}>
        <Header>
          <Title variant="h4">Edição de Pedido</Title>
          {order.responsible_operator?.cognito_id === currentUser?.username ? (
            <ResponsibleOperatorText variant="body1">
              Você é o atual responsável por esse pedido
            </ResponsibleOperatorText>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickTakeResponsibility()}
            >
              Assumir responsabilidade
            </Button>
          )}
        </Header>
        <Body>
          <Box>
            <Typography variant="body1">
              <HighlightedText>Pedido:</HighlightedText>{' '}
              {order?.request_number_temp}
            </Typography>
            <Typography variant="body1">
              <HighlightedText>Operador Responsável:</HighlightedText>{' '}
              {order?.responsible_operator?.name ?? '?'}
            </Typography>
            <Typography variant="body1">
              <HighlightedText>Tipo de Pedido:</HighlightedText>{' '}
              {order?.request_type}
            </Typography>
          </Box>
          <StatusBox>
            <ComboBox
              label="Status"
              value={order.status_id}
              onChange={handleChangeStatus}
              items={visibleSelectItems.status}
              sx={{ width: '20.5%' }} />
            <ComboBox
              label="Complemento do Status"
              value={order.status_complement_id}
              onChange={handleChangeStatusComplement}
              items={visibleSelectItems.statusComplements}
              errorHelperText={errorValidationMessage.statusComplement}
              sx={{ width: '40.5%' }} />
            <ComboBox
              label="Filial Distribuidora"
              value={
                order.distribution_center == ''
                  ? 'none'
                  : order.distribution_center
              }
              onChange={handleChangeDistributionCenter}
              items={visibleSelectItems.distributionCenters}
              errorHelperText={errorValidationMessage.distributionCenters}
              disabled={false}
              sx={{ width: '20.5%' }} />
            <>
              <input
                type="file"
                onChange={handleChangeCECFile}
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                multiple />
              <Tooltip title={'Fazer upload de comprovante'}>
                <IconButton>
                  <BsFillFileEarmarkPlusFill
                    style={{
                      fontSize: '22px',
                      color: 'PrimaryTitleGray',
                      display: 'none',
                    }}
                    onClick={uploadCECFile}
                  />
                </IconButton>
              </Tooltip>
            </>
          </StatusBox>

          <DeliveryAndClassificationBox>
            <DeliveryBox>
              <SubTitle variant="h5">Entrega</SubTitle>

              <DatePicker
                label="Estimativa de Entrega"
                handleChange={(date) =>
                  setOrder({ ...order, delivery_forecast: date })
                }
                value={order.delivery_forecast} />
            </DeliveryBox>

            <ClassificationBox>
              <SubTitle variant="h5">Classificação do Pedido</SubTitle>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <MultipleSelect
                  label="Operações de entrega"
                  value={order.delivery_operations}
                  onChange={handleChangeDeliveryOperation}
                  items={visibleSelectItems.deliveryOperations}
                  sx={{ width: '30%' }} />

                <MultipleSelect
                  label="Eventos do atendimento"
                  value={order.service_events}
                  onChange={handleChangeServiceEvent}
                  items={visibleSelectItems.serviceEvents}
                  sx={{ width: '30%' }} />

                <Tooltip
                  title={
                    order?.request_type !== 'Recolhimento'
                      ? 'Opção disponível apenas para pedidos de recolhimento'
                      : undefined
                  }
                  sx={{ width: '30%' }}
                >
                  <ComboBox
                    label="Motivos do recolhimento"
                    value={order.retreat_reasons}
                    onChange={handleChangeRetreatReason}
                    items={visibleSelectItems.retreatReasons}
                    errorHelperText={errorValidationMessage.retreatReason} />
                </Tooltip>
              </Box>
            </ClassificationBox>
          </DeliveryAndClassificationBox>

          <SapDeliveryWindowAndTriageBox>
            <DeliveryWindowRadioButtonBox>
              <Checkbox
                row
                items={order.delivery_window}
                handleCheck={handleCheckDeliveryWindow}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '15px',
                }} />
              <SapNumberInput
                numbers={order.request_number}
                setNumbers={(newNumbers: string[]) =>
                  setOrder({ ...order, request_number: newNumbers })
                }
                setCECsent={setCECsent}
                CECsent={CECsent}
              />
            </DeliveryWindowRadioButtonBox>
            {currentUser?.isWhiteMartins || currentUser?.isAdmin ? (
              <Box sx={{ width: '78%' }}>
                <TriageField
                  orderId={order_id}
                  inputField={true}
                  showTitle={true}
                  open={open}
                ></TriageField>
              </Box>
            ) : (
              <></>
            )}
          </SapDeliveryWindowAndTriageBox>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Salvar
            </Button>
          </Box>
        </Body>
      </Modal>
      <ErrorModal
        title={''}
        showErrorModal={showCECErrorModal}
        setShowErrorModal={setShowCECErrorModal}
        message="O nome do arquivo deve possuir apenas números e deve ter 8 dígitos. Certifique-se que o número SAP foi adicionado ao pedido." />
      <InfoModal
        open={showCECSuccessModal}
        handleClose={() => setShowCECSuccessModal(false)}
        message="Upload de CEC realizado com sucesso!" 
      />
    </>
  );
};
